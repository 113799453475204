import { Toolbar, translate, useCreate, useNotify, useRefresh } from 'react-admin'
import Button from '@material-ui/core/Button'
import React, { useCallback, useState } from 'react'
import addMinutes from 'date-fns/addMinutes'
import { useForm } from 'react-final-form'

const CustomSaveButton = (props) => {
  const [create] = useCreate('items')
  const notify = useNotify()
  const refresh = useRefresh()
  const { data } = props
  const form = useForm()
  const [isSaving, setSaving] = useState(false)

  const handleSave = useCallback(
    async (values) => {
      try {
        setSaving(true)
        await Promise.all(
          data.map((item, index) => {
            const sanitizedData = {
              removal: values.removal,
              inspection: values.inspection,
              startDate: values.startDate,
              endDate: addMinutes(new Date(values.endDate), index),
            }
            const sanitize = { ...item, ...sanitizedData }
            delete sanitize.createdAt
            delete sanitize.updatedAt
            delete sanitize.shortId
            delete sanitize._id
            delete sanitize.currentValue
            delete sanitize.currentBidId
            delete sanitize.currentMaxBidId
            delete sanitize.currentBidderId
            delete sanitize.invoiceId
            delete sanitize.numberOfBids
            delete sanitize.emailNotification
            delete sanitize.auctionId

            Object.keys(sanitize).forEach((key) => {
              if (sanitize[key] === null) {
                sanitize[key] = undefined
              }
            })
            return create({
              payload: { data: { ...sanitize } },
            })
          }),
        )
        notify('Items created', 'info')
        refresh()
      } catch (e) {
        console.log('e ===', e)
        notify('Creating items failed.', 'error')
      } finally {
        setSaving(false)
        props.onFinish()
      }
    },
    [create, notify, data, setSaving],
  )

  return (
    <Button
      onClick={() => handleSave(form.getState().values)}
      variant={'contained'}
      color={!isSaving ? 'primary' : 'default'}
    >
      {isSaving ? 'Saving...' : 'Create Multiple'}
    </Button>
  )
}

const MultiRepostToolbar = translate(({ onCancel, ...props }) => {
  return (
    <Toolbar {...props}>
      <CustomSaveButton data={props.data} onFinish={onCancel} />
      <Button onClick={onCancel}>Cancel</Button>
    </Toolbar>
  )
})

export default MultiRepostToolbar
