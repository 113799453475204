import React, { useCallback } from 'react'
import { SaveButton, useCreate, useNotify, useRefresh } from 'react-admin'

const CustomSaveButton = (props) => {
  const [create] = useCreate('items')
  const notify = useNotify()
  const refresh = useRefresh()
  const { basePath } = props
  const handleSave = useCallback(
    async (values) => {
      const sanitize = { ...values }
      delete sanitize.createdAt
      delete sanitize.updatedAt
      delete sanitize.shortId
      delete sanitize._id
      delete sanitize.currentValue
      delete sanitize.currentBidId
      delete sanitize.currentMaxBidId
      delete sanitize.currentBidderId
      delete sanitize.invoiceId
      delete sanitize.numberOfBids
      delete sanitize.emailNotification
      Object.keys(sanitize).forEach((key) => {
        if (sanitize[key] === null) {
          sanitize[key] = undefined
        }
      })
      await create(
        {
          payload: { data: { ...sanitize } },
        },
        {
          onSuccess: () => {
            notify('Item created', 'info')
            refresh()
          },
        },
      )
      props.onFinish()
    },
    [create, notify, basePath],
  )
  return <SaveButton {...props} onSave={handleSave} label="Create" />
}

export default CustomSaveButton
