import Typography from '@material-ui/core/Typography'
import { format, parseISO } from 'date-fns'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { FormTab, useGetOne } from 'react-admin'
import React, { useState } from 'react'
import useEmails from './useEmails'
import usePayments from './usePayments'
import useInvoiceStatus from './useInvoiceStatus'

const ActionTab = (props) => {
  const [loading, setLoading] = useState(false)
  const { data: invoiceData } = useGetOne('invoices', props.id)

  const generateInvoiceEmails = useEmails(invoiceData)
  const switchInvoiceStatus = useInvoiceStatus(invoiceData)
  const { handleValueChange, handlePaymentTypeChange, makePayment, paymentType, value } = usePayments(
    invoiceData,
    setLoading,
  )

  return (
    <FormTab {...props} label="actions" path="actions">
      {!invoiceData._id ? null : (
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '50%' }}>
            <Typography
              variant="h5"
              color={invoiceData.status === 'paid' ? 'primary' : 'textPrimary'}
              style={{ marginTop: '16px' }}
            >
              {invoiceData.status === 'paid'
                ? `Invoice paid: ${format(parseISO(invoiceData.paymentDate?.toString()), 'MM.dd.yyyy HH:mm')}`
                : 'Make a payment'}
            </Typography>
            <InputLabel style={{ marginTop: '16px' }}>Payment type</InputLabel>
            <Select
              onChange={handlePaymentTypeChange}
              value={paymentType}
              disabled={invoiceData.status === 'paid' || loading}
              style={{ width: '200px' }}
            >
              <MenuItem value="card">Card</MenuItem>
              <MenuItem value="cash">Cash</MenuItem>
              <MenuItem value="wire">Wire</MenuItem>
            </Select>
            <br />
            <Typography component="span" variant="caption" color="textSecondary">
              Total Paid
            </Typography>
            <div style={{ padding: '8px 0px 4px 0px' }}>
              <Typography component="span" variant="body2" {...props}>
                {(Number(invoiceData.paymentAmount || 0) / 100).toFixed(2)}
                {` of `}
                {(Number(invoiceData.summary.bidderTotal || 0) / 100).toFixed(2)}
              </Typography>
            </div>
            <InputLabel style={{ marginTop: '16px' }}>Add new payment</InputLabel>
            <Input onChange={handleValueChange} value={value} type={'number'} />
            <br />

            {invoiceData.refundValue ? (
              <>
                <Typography component="span" variant="caption" color="textSecondary">
                  Refund value
                </Typography>
                <div style={{ padding: '8px 0px 4px 0px' }}>
                  <Typography component="span" variant="body2" {...props}>
                    {(Number(invoiceData.refundValue || 0) / 100).toFixed(2)}
                  </Typography>
                </div>
              </>
            ) : null}
            <Button
              variant={'contained'}
              color={'primary'}
              style={{ marginTop: '16px' }}
              onClick={makePayment}
              disabled={loading || !paymentType}
            >
              Submit
            </Button>
          </div>
          <div style={{ width: '50%' }}>
            <Typography variant="h5" color="textPrimary" style={{ marginTop: '16px' }}>
              Other actions
            </Typography>
            <Button
              variant={'contained'}
              color={'default'}
              style={{ marginTop: '16px' }}
              disabled={loading}
              onClick={switchInvoiceStatus}
            >
              Mark invoice as
              {invoiceData.status === 'invoiced' && ' inactive'}
              {invoiceData.status === 'inactive' && ' invoiced'}
              {invoiceData.status === 'paid' && ' inactive'}
            </Button>
            {invoiceData.status === 'paid' ? (
              <Typography color={'error'} variant={'subtitle2'}>
                Warning: Invoice is already paid
              </Typography>
            ) : null}
            <br />
            <Button
              variant={'contained'}
              color="default"
              style={{ marginTop: '16px' }}
              onClick={() => {
                generateInvoiceEmails('bidder')
              }}
              disabled={loading || invoiceData.status === 'inactive'}
            >
              Resend
              {invoiceData.status === 'paid' && ' receipt '}
              {invoiceData.status === 'invoiced' && ' invoice '}
              to bidder
            </Button>
            <br />
            <Button
              variant={'contained'}
              color="default"
              style={{ marginTop: '16px' }}
              onClick={() => {
                generateInvoiceEmails('seller')
              }}
              disabled={loading || invoiceData.status === 'inactive'}
            >
              Resend
              {invoiceData.status === 'paid' && ' receipt '}
              {invoiceData.status === 'invoiced' && ' invoice '}
              to seller
            </Button>
          </div>
        </div>
      )}
    </FormTab>
  )
}

export default ActionTab
